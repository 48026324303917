import EventEmitter from '../../library/event-emitter.js';

const SHIFTED_STATE = {
	START: -1,
	NONE: 0,
	END: 1,
};

export default class LoopManager {
	#eventEmitter;

	constructor(carousel, modeInstance) {
		const _ = this;
		_.carousel = carousel;
		_.mode = modeInstance;

		_.track = carousel.track;

		_.#eventEmitter = new EventEmitter();

		_.bindEvents();
		_.reInit();
	}

	reInit() {
		const _ = this;

		_.slides = _.carousel.slides;
		_.options = _.carousel.options;
		_.slidesToShow = _.options.slidesToShow;
		_.slidesToScroll = _.options.slidesToScroll;

		_.viewportWidth = _.carousel.viewport.offsetWidth;
		_.viewportWidthHalf = _.carousel.viewport.offsetWidth / 2;

		_.checkShift();

		_.canLoop = _.canWeLoop();
	}

	bindEvents() {
		const _ = this;

		_.carousel.on('optionsChanged', () => {
			_.reInit();
		});

		_.carousel.on('slidesChanged', () => {
			_.reInit();
		});

		_.carousel.on('windowResize', () => {
			_.reInit();
		});
	}

	canWeLoop() {
		const _ = this;
		// options set to false
		if (!_.options.loop) return false;
		// must have enough slides to properly loop
		if (_.slides.length > _.slidesToShow * 2) {
			return true;
		}
		// not enough slides to loop
		return false;
	}

	updateLoop(trackPos) {
		const _ = this;

		// check track position to see if it's positive
		if (!_.canWeLoop()) {
			if (_.currentState !== SHIFTED_STATE.NONE) {
				// reset slides
				_.wrapReset();
			}
			return;
		}

		// check for shift first
		// they happen at different trigger points
		_.checkShift(trackPos);
	}

	checkShift(trackPos) {
		const _ = this;
		const trackWidth = _.mode.trackWidth;
		const startTriggerPoint = _.viewportWidth * 0.6;
		const endTriggerPoint = (trackWidth - _.viewportWidth * 0.6) * -1;

		// looping from front to back
		if (trackPos > startTriggerPoint) {
			// shift to back
			_.wrapEnd();
			_.#eventEmitter.emit('shiftTrackForward');
			return;
		} else if (trackPos < endTriggerPoint) {
			// shift to front
			_.wrapStart();
			_.#eventEmitter.emit('shiftTrackBackwards');
			return;
		}

		// check wrap if we didn't shift
		_.checkWrap(trackPos);
	}

	checkWrap(trackPos) {
		const _ = this;

		const startTriggerPoint = _.viewportWidthHalf * -1;
		const trackWidth = _.mode.trackWidth;
		const endTriggerPoint = (trackWidth - _.viewportWidth) * -1;

		if (trackPos > startTriggerPoint) {
			// wrap front
			_.wrapStart();
		}

		if (trackPos < endTriggerPoint) {
			// wrap end
			_.wrapEnd();
		}

		if (
			trackPos >= endTriggerPoint &&
			trackPos <= startTriggerPoint
		) {
			// console.log(
			// 	`trackWidth: ${trackWidth}, trackPos: ${trackPos}, viewportWidth: ${_.viewportWidth}, endTriggerPoint: ${endTriggerPoint}, `
			// );
			_.wrapReset();
		}
	}

	// wrap moves the slides from the end to the start
	wrapStart() {
		const _ = this;

		// already shifted to start
		if (_.currentState == SHIFTED_STATE.START) return;

		const slides = _.carousel.slides;
		const slidesToShow = _.carousel.options.slidesToShow;

		// calculate render index
		for (let i = 0, n = slides.length; i < n; ++i) {
			if (i < n - slidesToShow) {
				// set index to normal
				slides[i].setRenderIndex(i);
			} else {
				// set render index to shifted state
				slides[i].setRenderIndex(i - n);
			}
		}

		// console.log('wrap start');
		// wrap end slides to the beginning
		_.currentState = SHIFTED_STATE.START;
		_.#eventEmitter.emit('slidePositionsChanged');
	}

	// wrap moves the slides from the start to the end
	wrapEnd() {
		const _ = this;

		// already shifted to end
		if (_.currentState == SHIFTED_STATE.END) return;

		const slides = _.carousel.slides;
		const slidesToShow = _.carousel.options.slidesToShow;

		// calculate render index
		for (let i = 0, n = slides.length; i < n; ++i) {
			if (i > slidesToShow) {
				// set index to normal
				slides[i].setRenderIndex(i);
			} else {
				// set render index to shifted state
				slides[i].setRenderIndex(i + n);
			}
		}

		// console.log('wrap end');
		_.currentState = SHIFTED_STATE.END;
		_.#eventEmitter.emit('slidePositionsChanged');
	}

	wrapReset() {
		const _ = this;

		// exit if already reset
		if (_.currentState === SHIFTED_STATE.NONE) return;

		const slides = _.carousel.slides;
		for (let i = 0, n = slides.length; i < n; ++i) {
			// reset slide render indexes
			slides[i].setRenderIndex(i);
		}

		// console.log('\nwrap RESET ******');
		_.currentState = SHIFTED_STATE.NONE;
		_.#eventEmitter.emit('slidePositionsChanged');
	}

	on(eventName, eventFunction) {
		this.#eventEmitter.on(eventName, eventFunction);
	}

	destroy() {
		this.wrapReset();
	}
}
