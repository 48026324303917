export default class TransformRegion {
	constructor(options) {
		const _ = this;
		const { name, color, property } = options;

		_.enabled = true;

		// primarily used this to debug
		_.name = name;

		_.property = property;
	}

	updateOptions({ startPos, endPos, startValue, endValue, enabled = true }) {
		Object.assign(this, {
			enabled,
			startPos,
			endPos,
			startValue,
			endValue,
		});
	}

	applyTransform(slide, percent, trackPos) {
		const _ = this;

		// exit if marker isn't active
		if (!_.enabled || percent === 0) return;

		// normalize positions with track Pos
		trackPos *= -1;
		const startPos = trackPos + _.startPos;
		const endPos = trackPos + _.endPos;
		const centerPos = slide.centerPos.toFixed(3);

		let percentInRange = 0;

		// if start pos is higher than end pos
		if (startPos > endPos) {
			// if we are in range
			if (centerPos > endPos && centerPos <= startPos) {
				const range = startPos - endPos;
				const posInRange = centerPos - endPos;
				percentInRange = posInRange / range;
			}
		} else {
			// if we are in range
			if (centerPos >= startPos && centerPos < endPos) {
				const range = endPos - startPos;
				const posInRange = centerPos - startPos;
				percentInRange = 1 - posInRange / range;
			}
		}

		// exit if we aren't in the range
		if (percentInRange <= 0) return;

		const valueRange = _.startValue - _.endValue;
		const valueChange = percentInRange * valueRange;

		const finalValueApplied = percent * (_.endValue + valueChange);
		slide[_.property] += finalValueApplied;
	}

	/*
	debug() {
		return {
			name: this.name,
			range: {
				start: this.startPos,
				end: this.endPos,
			},
			values: {
				start: this.startValue,
				end: this.endValue,
			},
			enabled: this.enabled,
		};
	}
	*/
}
