// methods.js

import { areOptionsEqual } from '../utilities/toolkit.js';

export default {
	// basic carousel methods
	next(velocity) {
		const _ = this;
		const oldPage = _.page;
		let newPage = _.page + 1;

		// don't let new page go out of bounds
		if (newPage >= _.pageCount) {
			if (_.options.loop) {
				// if we are looping go back to first page
				newPage = 0;
			} else {
				// if we aren't looping, stay at end of track
				newPage = _.pageCount - 1;
				// bump animation to show end of track
				if (!velocity) velocity = -10;
			}
		}
		// save new page to carousel
		_.page = newPage;
		// emit change
		_.emit('pageChanged', { oldPage, newPage, velocity });
	},
	prev(velocity) {
		const _ = this;
		const oldPage = _.page;
		let newPage = _.page - 1;

		// don't let new page go out of bounds
		if (newPage < 0) {
			if (_.options.loop) {
				// if we are looping, go to last page
				newPage = _.pageCount - 1;
			} else {
				// if we aren't looping, return to 0
				newPage = 0;
				// bump animation to show end of track
				if (!velocity) velocity = 10;
			}
		}
		// save new page to carousel
		_.page = newPage;
		// emit change
		_.emit('pageChanged', { oldPage, newPage, velocity });
	},

	/**
	 * goes to a specific slide by index
	 * @param {number} index - the index of the slide to go to
	 * @throws {Error} if the index is outside the valid range
	 */
	goToSlide(index) {
		const _ = this;
		const length = _.slides.length - 1;

		// throw an error if the requested index is outside the range
		if (index > length || index < 0) {
			throw new Error(
				`Slide index ${index} is out of bounds. Valid range is 0 to ${length}.`
			);
		}

		// don't go if we're already there
		if (_.displayIndex === index) return;

		_.mode.goToSlide(index);
	},

	jumpToSlide(index) {
		const _ = this;
		const length = _.slides.length - 1;

		// throw an error if the requested index is outside the range
		if (index > length || index < 0) {
			throw new Error(
				`Slide index ${index} is out of bounds. Valid range is 0 to ${length}.`
			);
		}

		// don't go if we're already there
		if (_.displayIndex === index) return;

		_.mode.jumpToSlide(index);
	},

	goToPage(newPage, velocity = 0) {
		const _ = this;

		// stay within bounds
		if (newPage < 0) return;
		if (newPage > _.pageCount) return;

		// save new page to carousel
		const oldPage = _.page;
		_.page = newPage;

		// emit change
		_.emit('pageChanged', { oldPage, newPage, velocity });
	},

	jumpToPage(index) {
		this.mode.jumpToPage(index);
	},

	// set methods
	setOptions(newOptions) {
		const _ = this;

		// check to see if newOptions is different from _.options
		if (!areOptionsEqual(_.options, newOptions)) {
			_.options = newOptions;
			// emit options changed event
			_.emit('optionsChanged');
		}
	},

	// get methods

	getPage() {
		return this.page;
	},

	getSelectedIndex() {
		return this.selectedIndex;
	},
	getSelectedSlide() {
		return this.slides[this.selectedIndex];
	},

	getSlideAtIndex(index) {
		return this.slides[index];
	},

	// get option functions
	getUserOptions() {
		return this.optionsManager.getUserOptions();
	},

	getOptions() {
		return this.options;
	},

	getCurrentBreakpointOptions() {
		return this.currentBreakpoint.options;
	},

	/**
	 * Set new options for the carousel.
	 * @param {Object} newOptions - The new options to set.
	 */
	updateOptions(newOptions) {
		this.optionsManager.updateOptions(newOptions);
	},

	// event hooks
	on(event, listener) {
		this.events.on(event, listener);
	},

	off(event, listener) {
		this.events.off(event, listener);
	},

	emit(event, ...args) {
		// console.log('emit event', event, args)
		this.events.emit(event, args);
	},

	// add mode to mode manager
	addMode(newMode) {
		this.modeManager.addMode(newMode);
	},
};
