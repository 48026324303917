import PhysicsEngine from '../../library/physics-engine.js';

export default class RippleWindow {
	#value = 0;
	#maxValue = 0;

	constructor(carousel, mode) {
		const _ = this;
		_.carousel = carousel;
		_.mode = mode;
		_.viewport = carousel.viewport;
		_.track = carousel.track;

		_.engine = new PhysicsEngine({
			attraction: _.carousel.options.attraction,
			friction: _.carousel.options.friction,
		});
	}

	init() {
		const _ = this;
		_.bindEvents();
		_.reInit();
	}

	reInit() {
		const _ = this;
		_.options = _.carousel.options;
		_.viewportWidth = _.viewport.offsetWidth;
		_.gapWidth = _.mode.gapWidth;
		_.slideWidth = _.mode.slideWidth;
		_.slideAndGapWidth = _.slideWidth + _.gapWidth;
		_.trackStartPos = _.mode.paddingLeftWidth;

		// we stop tracking after end position
		_.trackEndPos =
			_.viewportWidth -
			_.mode.trackWidth +
			_.gapWidth -
			_.mode.paddingRightWidth;
	}

	bindEvents() {
		const _ = this;

		_.carousel.on('windowResize', () => {
			_.reInit();
		});

		_.carousel.on('optionsChanged', () => {
			_.reInit();
		});

		// Set up event listeners for engine events
		_.engine.on('positionChanged', ({ position, progress, posDelta }) => {
			const trackPos = _.mode.animator.getCurrentPos();
			_.addAmount(posDelta, trackPos);
			_.mode.positionSlides();
			// console.log('Window pos delta', posDelta);
			// console.log('SNAP to Value', _.#value);

			if (_.#value == 0) {
				_.engine.stop();
			}
		});
	}

	setMaxValue(maxValue) {
		this.#maxValue = maxValue;
	}

	addAmount(amount, trackPos) {
		const _ = this;

		// if we aren't looping
		if (!_.options.loop) {
			// track start of track reached
			if (trackPos > _.trackStartPos) return;
			// end of track reached
			if (trackPos < _.trackEndPos) return;
		}

		// not looping & transitiong back to first slide
		if (!_.options.loop && amount > 0 && trackPos > -1 * _.mode.slideWidth) {
			// add delta amount to value
			_.#value -= amount;

			// not looping & transitioning to last slide
		} else if (
			!_.options.loop &&
			amount < 0 &&
			trackPos < _.trackEndPos + _.slideAndGapWidth
		) {
			_.#value -= amount;

			// default - add delta amount to value
		} else {
			_.#value += amount;
		}

		// make sure we stay within bounds
		if (_.#value < 0) {
			_.#value = 0;
		} else if (_.#value > _.#maxValue) {
			_.#value = _.#maxValue;
		}
		return _.#value;
	}

	getValue() {
		return this.#value;
	}

	getPercent() {
		return this.#value / this.#maxValue;
	}

	snapToValue() {
		const _ = this;

		// arleady snapped at value
		if (_.#value === 0 || _.#value === _.#maxValue) return;

		if (_.#value > _.#maxValue / 2) {
			// tell engine to animate to max value
			_.engine.animateTo(_.#value, _.#maxValue, 0);
			return;
		}

		// tell engine to animate to 0
		_.engine.animateTo(_.#value, 0, 0);
	}
}
