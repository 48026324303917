import SlideManager from './core/slide-manager.js';
import OptionsManager from './core/options-manager.js';
import ModeManager from './core/mode-manager.js';
import DragHandler from './library/drag-handler.js';
import EventEmitter from './library/event-emitter.js';

export default class TarotCarousel extends HTMLElement {
	static plugins = [];
	static instanceCount = 0;

	constructor() {
		super();
		const _ = this;

		// Check if the carousel has an ID, if not, assign one
		if (!_.id) {
			_.id = `tarot-carousel-${TarotCarousel.instanceCount}`;
		}

		// increment count
		TarotCarousel.instanceCount++;

		// variables
		_.pluginInstances = []; // Holds instantiated plugins
		_.mode = false; // Static plugins that will be registered
		_.options = {}; // optionsManager will save merged options here
		_.page = 0; // current active page
		_.pageCount = 1;
		_.selectedSlide;

		// carousel elements
		_.track = _.querySelector(':scope tarot-slides');
		_.viewport = _.querySelector(':scope tarot-viewport');
		_.slides = []; // Contains all active slides as a node list
		_.playButton = null; // might add later
		_.prevButton = null;
		_.nextButton = null;

		// manages event hooks
		_.events = new EventEmitter();

		// loads user options and manages breakpoint changes
		_.optionsManager = new OptionsManager(_);

		// detects and manages all slides and
		// saves all active slides back to the _.slides array
		_.slideManager = new SlideManager(_);

		// manage modes (carousel, fade, ripple, etc...)
		_.modeManager = new ModeManager(_);

		// binds to viewport for drag events and
		// passes drag data to the mode instance
		_.dragHandler = new DragHandler(_);

		_.init();
	}

	/**
	 * Register a plugin class to be initialized later.
	 * @param {Function} plugin - The plugin class to be registered.
	 * @returns {TarotCarousel} - Returns the carousel instance for chaining.
	 */
	static use(plugin) {
		const _ = this;
		if (!_.plugins) {
			_.plugins = [];
		}
		_.plugins.push(plugin);
		return _;
	}

	/**
	 * Initialize the carousel and activate all registered plugins.
	 */
	init() {
		const _ = this;
		// Static plugins that will be registered
		const plugins = _.constructor.plugins;

		// Init all registered plugins
		plugins.forEach((PluginClass) => {
			// console.log('creating plugin\n', PluginClass)
			// Pass the carousel instance to the plugin
			const pluginInstance = new PluginClass(_);
			// Save the plugin instance
			_.pluginInstances.push(pluginInstance);
		});
	}

	/**
	 * Reinitialize the carousel and plugins.
	 */
	reInit() {
		const _ = this;
		// Reinitialize the carousel logic here...

		// Call reInit on each active plugin if they implement it
		_.pluginInstances.forEach((plugin) => {
			plugin.reInit();
		});
	}

	/**
	 * Destroy the carousel and cleanup all plugins.
	 */
	destroy() {
		const _ = this;
		// Core carousel cleanup logic...

		// Call destroy on each plugin if they implement it
		_.pluginInstances.forEach((plugin) => {
			plugin.destroy();
		});

		// Clear plugin instances
		_.pluginInstances = [];
	}
}
