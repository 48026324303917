import PhysicsEngine from '../../library/physics-engine.js';
import EventEmitter from '../../library/event-emitter.js';
import LoopManager from './loop-manager.js';

export default class TrackAnimator {
	#eventEmitter;
	#currentPos = 0;
	#dragStartPos = 0;
	#minVelocity = 0;
	#targetPos = 0;

	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;
		_.track = carousel.track;

		// load new physics engine with attraction and friction options
		_.engine = new PhysicsEngine({
			attraction: _.carousel.options.attraction,
			friction: _.carousel.options.friction,
		});

		_.#dragStartPos = 1;

		_.#eventEmitter = new EventEmitter();

		_.bindEvents();
	}

	init() {}

	reInit() {}

	bindEvents() {
		const _ = this;

		_.carousel.on('optionsChanged', () => {
			_.engine.setAttraction(_.carousel.options.attraction);
			_.engine.setFriction(_.carousel.options.friction);
		});

		// Set up event listeners for engine events
		_.engine.on('positionChanged', ({ position, progress, posDelta }) => {
			if (progress === 1) {
				_.setPos(_.#targetPos, progress, 'animate');
			} else {
				_.setPos(_.#currentPos + posDelta, progress, 'animate');
			}
		});

		_.engine.on('animationFinished', () => {
			// relay finished event
			_.#eventEmitter.emit('animationFinished', _.#targetPos);
		});
	}

	/**
	 * Gets the current X position.
	 * @returns {number} - The current X position.
	 */
	getCurrentPos() {
		return this.#currentPos;
	}

	getIsAnimating() {
		return this.engine.isAnimating();
	}

	// drag events
	dragStart() {
		const _ = this;
		_.stop();
		_.#dragStartPos = _.#currentPos;
	}

	dragMove(event, drag) {
		const _ = this;
		// console.log('animator: drag move', _.#dragStartPos, drag.delta);
		_.setPos(_.#dragStartPos + drag.delta, null, 'drag');
	}

	stop() {
		const _ = this;
		_.engine.stop();
	}

	animateToPosition(targetPos, velocity) {
		const _ = this;

		// calculate min velocity
		if (!velocity) {
			if (targetPos < _.#currentPos) {
				velocity = _.#minVelocity * -1;
			} else {
				velocity = _.#minVelocity;
			}
		}

		// save target position
		_.#targetPos = targetPos;

		// tell engine to go to target with velocity
		_.engine.animateTo(_.#currentPos, targetPos, velocity);
	}

	jumpToPosition(position) {
		this.stop();
		this.setPos(position, 1, 'jump');
	}

	setPos(newPos, progress, type) {
		const _ = this;

		// exit if position didnt' change
		if (_.#currentPos === newPos) return;

		const delta = newPos - _.#currentPos;

		// save new position
		_.#currentPos = newPos;
		// set DOM element to target position
		_.track.style.transform = `translateX(${newPos}px)`;

		// emit position changed - used by sync-with
		_.#eventEmitter.emit('positionChanged', newPos, delta, progress, type);
		_.carousel.emit('positionChanged', newPos, delta, progress, type);
	}

	shiftTrackForward() {
		const _ = this;
		const rectWidth = _.track.getBoundingClientRect().width;

		// shift track by full length
		_.#currentPos -= rectWidth;
		_.#dragStartPos -= rectWidth;
		_.#targetPos -= rectWidth;

		_.track.style.transform = `translateX(${_.#currentPos}px)`;
	}

	shiftTrackBackwards() {
		const _ = this;
		const rectWidth = _.track.getBoundingClientRect().width;

		// shift track by full length
		_.#currentPos += rectWidth;
		_.#dragStartPos += rectWidth;
		_.#targetPos += rectWidth;

		_.track.style.transform = `translateX(${_.#currentPos}px)`;
	}

	/**
	 * Adds an event listener for the specified event.
	 * @param {string} eventName - The name of the event.
	 * @param {function} eventFunction - The function to call when the event is triggered.
	 */
	on(eventName, eventFunction) {
		this.#eventEmitter.on(eventName, eventFunction);
	}

	destroy() {
		const _ = this;
		_.engine = null;
		_.#eventEmitter = null;
	}
}
