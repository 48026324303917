export default class OptionsManager {
  constructor(carousel) {
    const _ = this;
    _.carousel = carousel;
    _.defaultOptions = {
      asNavFor: false,
      attraction: 0.026,
      autoplay: 0,
      breakpoints: [],
      dragThreshold: 60,
      filterClass: '',
      friction: 0.24,
      focusOnSelect: false,
      gap: 0,
      initialIndex: 0,
      loop: true,
      mode: 'carousel',
      pageDots: 'dots',
      pageDotsSelector: false,
      paddingLeft: 0,
      paddingRight: 0,
      showButtons: true,
      showLeftButton: true,
      showRightButton: true,
      leftButtonSelector: false,
      rightButtonSelector: false,
      slideMinWidth: 0.25,
      slidesToShow: 1,
      slidesToScroll: 1,
      smartButtons: false,
      stopAutoplayAfterInteraction: true,
      syncWith: false,
    };
    _.userOptions = {};
    _.userOptionsElement = _.carousel.querySelector(
      ':scope > [data-tarot-options]'
    );
    _.currentBreakpoint = { minWidth: 0, options: {} };
    _.prevBreakpoint = { minWidth: 0, options: {} };
    _.options = {};

    _.boundCheckBreakpoints = _.checkBreakpoints.bind(_);

    _.init();
  }

  init() {
    const _ = this;

    _.bindEvents();

    // load user options
    _.loadUserOptions();
    // get current breakpoint options
    _.currentBreakpoint = _.calculateCurrentBreakpoint();
    // prev breakpoint is equal to current breakpoint
    _.prevBreakpoint = { ..._.currentBreakpoint };
    // load merged options
    _.loadMergedOptions(false);

    if (_.options.slidesToScroll === 0) {
      _.options.slidesToScroll = _.options.slidesToShow;
    }
  }

  reInit() {
    this.checkBreakpoints();
  }

  bindEvents() {
    window.car = this.carousel;
    this.carousel.on('windowResize', this.boundCheckBreakpoints);
  }

  loadUserOptions() {
    const _ = this;

    // no user options to load
    if (!_.userOptionsElement) return;

    // get content and remove new lines
    let optionsText = _.userOptionsElement.textContent
      .split('\n')
      .join('')
      .trim();
    // add quotes around keys that don't have them
    optionsText = optionsText.replace(/(\w+:)|(\w+ :)/g, function (s) {
      return `"${s.substring(0, s.length - 1)}":`;
    });

    try {
      _.userOptions = JSON.parse(optionsText);
    } catch (error) {
      console.error('Error parsing user options:', error);
    }
  }

  loadMergedOptions(triggerEvent = true) {
    const _ = this;
    const options = _.carousel.getOptions();

    // merge new options
    const newOptions = {
      ..._.defaultOptions,
      ..._.userOptions,
      ..._.currentBreakpoint.options,
    };

    // breakpoints not important here
    delete newOptions.breakpoints;

    // save back to carousel options
    _.options = newOptions;
    _.carousel.setOptions(newOptions);

    // exit if we don't want to trigger event
    if (!triggerEvent) return;
  }

  /**
   * Set new options for the carousel.
   * @param {Object} newOptions - The new options to set.
   */
  updateOptions(newOptions) {
    const _ = this;

    // Ensure slidesToScroll and slidesToShow are >= 1
    if (newOptions.slidesToScroll < 1) newOptions.slidesToScroll = 1;
    if (newOptions.slidesToShow < 1) newOptions.slidesToShow = 1;

    // Merge new options into user options using deep merge
    _.userOptions = deepMerge(_.userOptions, newOptions);

    // remerge all options
    _.loadMergedOptions();

    // Calculate active breakpoint based on new options
    _.checkBreakpoints();

    // Reinitialize the carousel with the new options
    _.reinitCarousel();

    return _;
  }

  checkBreakpoints() {
    const _ = this;
    const activeBreakpoint = _.calculateCurrentBreakpoint();

    // if no active breakpoint or if it hasn't changed
    if (
      !activeBreakpoint ||
      activeBreakpoint.minWidth === _.currentBreakpoint.minWidth
    )
      return;

    const prevOptions = { ..._.options };
    _.prevBreakpoint = _.currentBreakpoint;
    _.currentBreakpoint = activeBreakpoint;
    _.loadMergedOptions();

    // make sure slidesToScroll != 0
    if (_.options.slidesToScroll <= 0) {
      _.options.slidesToScroll = _.options.slidesToShow;
    }
  }

  calculateCurrentBreakpoint() {
    const windowWidth = window.innerWidth;
    const breakpoints = this.userOptions.breakpoints;
    if (!breakpoints || !Array.isArray(breakpoints)) return false;

    return breakpoints.reduce(
      (matchingBreakpoint, breakpoint) => {
        return windowWidth >= breakpoint.minWidth &&
          breakpoint.minWidth > matchingBreakpoint.minWidth
          ? breakpoint
          : matchingBreakpoint;
      },
      { minWidth: 0, options: {} }
    );
  }

  destroy() {}
}
