

export default class AsNavFor {

  constructor(carousel) {
    this.carousel = carousel;
    this.options = carousel.options;
    this.otherCarousel = null;

    this.init();
  }

  

  init() {
    const otherCarousel = document.querySelector(this.options.asNavFor);
    
    // can't find it
    if (!otherCarousel) return;

    this.otherCarousel = otherCarousel;
    
    // give DOM a few ms to load the custom elements 
    setTimeout(() => {
      this.otherCarousel.on('carouselWillTransition', (prevIndex, nextIndex) => {
        // return if we are already going to that index or already at it 
        if (this.displayIndex === nextIndex) {
          return;
        }
        // go to same display index as other carousel
        setTimeout(() => {
          this.goToSlide(nextIndex);
        }, 1);
      });
    }, 100);
  }


  reInit() {
    if (this.carousel.options.autoplay) {
      this.start();
    } else {
      this.stop();
    }
  }

  updateOtherCarousel() {
    // return if we don't have a carousel to update
    if (!this.otherCarousel) return;

    // tell carousel to go to the same display index
    // async hack
    setTimeout(() => {
      this.otherCarousel.goToSlide(this.displayIndex);    
    }, 1);
  }


}