function getPrevIndex(slides, currentIndex) {
	let prevIndex = currentIndex - 1;

	if (prevIndex < 0) {
		prevIndex += slides.length;
	}

	return prevIndex;
}

function getNextIndex(slides, currentIndex) {
	let nextIndex = currentIndex + 1;

	if (nextIndex > slides.length - 1) {
		return 0;
	}

	return nextIndex;
}

export { getPrevIndex, getNextIndex };
