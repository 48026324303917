

export default class LazyLoad {

	constructor(carousel){
		const _ = this;
		_.carousel = carousel;
		_.init()
	}

	init() {

	}

	bindEvents(){
		// bind to slides changed event

		// bind to options changed event
		
	}


	checkLazyImages () {
		const _ = this;
		const slides = _.slides
		let images;
		
		// remove all loading="lazy" attributes from images
		// to force them to load
		for (let i=0, n=slides.length; i<n; ++i){
			images = slides[i].querySelectorAll('img')

			for (let k=0,l=images.length; k<l; ++k){
				images[k].removeAttribute("loading");
			}
		}
		
	}


}