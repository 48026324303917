export default class PageDots {
	/**
	 * @constructor
	 * @param {Object} carousel - The carousel instance that the PageDots will control.
	 */
	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;

		_.paginationContainer = null;
		_.init();
	}

	/**
	 * Initialize the page dots for the carousel.
	 * Injects the pagination container into the DOM and binds events.
	 */
	init() {
		const _ = this;
		let wasSelfGenerated = false;

		// Check if an element is passed in to use
		if (_.carousel.options.pageDotsSelector) {
			const pageDotsElement = document.querySelector(
				_.carousel.options.pageDotsSelector
			);
			if (pageDotsElement) {
				_.paginationContainer = pageDotsElement;
			}
		}

		// Inject pagination container into DOM if not provided
		if (!_.paginationContainer) {
			wasSelfGenerated = true;

			// Create a <nav> element with aria-label
			const nav = document.createElement('nav');
			nav.setAttribute('aria-label', 'Carousel Navigation');

			// Create the pagination container as a <div>
			_.paginationContainer = document.createElement('div');
			nav.appendChild(_.paginationContainer);

			// Append the nav to the carousel element
			_.carousel.appendChild(nav);
		}

		// Clear existing content and set up the container
		_.paginationContainer.innerHTML = '';
		_.paginationContainer.classList.add('tarot-dots-container');

		// Create the <ul> element with role="tablist"
		_.dotsList = document.createElement('ul');
		_.dotsList.classList.add('tarot-dots-list');
		_.dotsList.setAttribute('role', 'tablist');

		_.paginationContainer.appendChild(_.dotsList);

		// Bind events and update pagination
		_.paginationContainer.addEventListener(
			'click',
			_.handlePaginationClick.bind(_),
			false
		);
		_.paginationContainer.addEventListener(
			'keydown',
			_.handleKeyDown.bind(_),
			false
		);

		// Build the elements
		_.render();

		// Show or hide pagination
		if (_.carousel.options.pageDots === false) {
			_.hide();
		} else {
			_.show();
		}

		// Bind carousel events
		_.bindEvents();
	}

	reInit() {
		this.render();
	}

	/**
	 * Build the pagination buttons for the carousel.
	 * Calculates the number of pages based on the slides and injects the buttons into the DOM.
	 */
	render() {
		const _ = this;
		const slides = _.carousel.slides;
		const page = _.carousel.page;
		const pageCount = _.getPageCount();

		// save page count
		_.carousel.pageCount = pageCount;

		// Clear existing dots
		_.dotsList.innerHTML = '';

		for (let i = 0; i < pageCount; ++i) {
			// Create <li> element with role="presentation"
			const listItem = document.createElement('li');
			listItem.setAttribute('role', 'presentation');

			// Create <button> element with necessary ARIA attributes
			const button = document.createElement('button');
			button.type = 'button';
			button.classList.add('tarot-dots-button');
			button.setAttribute('role', 'tab');
			button.setAttribute('data-page-index', i);
			button.setAttribute('data-action', 'tarot-go-to-page');
			button.setAttribute('aria-selected', page === i ? 'true' : 'false');
			if (page === i) {
				// button.setAttribute('aria-current', 'page');
				button.tabIndex = 0;
			} else {
				// button.removeAttribute('aria-current');
				button.tabIndex = -1;
			}
			button.setAttribute('aria-label', `Page ${i + 1}`);
			// button.setAttribute('aria-controls', `slide${i + 1}`);
			button.id = `carousel-tab${i + 1}`; // Ensure unique IDs

			// Create visually hidden <span>
			const span = document.createElement('span');
			span.className = 'visually-hidden';
			span.textContent = `Slide ${i + 1}`;

			// Append <span> to <button>
			button.appendChild(span);

			// Append <button> to <li>
			listItem.appendChild(button);

			// Append <li> to the dots list
			_.dotsList.appendChild(listItem);
		}

		// Set selected dot
		_.updateSelectedDot();
	}

	bindEvents() {
		const _ = this;
		const carousel = _.carousel;

		// Bind to optionsChanged
		carousel.on('optionsChanged', () => {
			_.render();
		});

		// Bind to slidesChanged
		carousel.on('slidesChanged', () => {
			_.render();
		});

		// Bind to pageChanged
		carousel.on('pageChanged', () => {
			_.updateSelectedDot();
		});
	}

	getPageCount() {
		const _ = this;
		const slideLength = _.carousel.slides.length;
		const slidesToShow = _.carousel.options.slidesToShow || 1;
		const slidesToScroll = _.carousel.options.slidesToScroll || 1;
		const loop = _.carousel.options.loop || false;

		// if we're looping return simple quantity
		if (loop) {
			return Math.ceil(slideLength / slidesToScroll);
		}

		// not looping - calculate differently
		// ((N - V) / S) + 1
		return (slideLength - slidesToShow) / slidesToScroll + 1;
	}

	/**
	 * Update the selected pagination dot based on the carousel's current state.
	 * Sets the aria-selected attribute on the active dot.
	 */
	updateSelectedDot() {
		const _ = this;
		const dots = _.paginationContainer.querySelectorAll('.tarot-dots-button');
		const currentPage = `${_.carousel.page}`;

		// Clear all dots
		dots.forEach((dot) => {
			if (dot.dataset.pageIndex === currentPage) {
				dot.setAttribute('aria-selected', 'true');
				// dot.setAttribute('aria-current', 'page');
				dot.tabIndex = 0;
				//dot.focus(); // Optionally move focus
			} else {
				dot.setAttribute('aria-selected', 'false');
				// dot.removeAttribute('aria-current');
				dot.tabIndex = -1;
			}
		});
	}

	/**
	 * Show the pagination dots by setting the display style.
	 */
	show() {
		if (this.paginationContainer.parentElement) {
			this.paginationContainer.parentElement.style.display = '';
		} else {
			this.paginationContainer.style.display = '';
		}
	}

	/**
	 * Hide the pagination dots by setting the display style to none.
	 */
	hide() {
		if (this.paginationContainer.parentElement) {
			this.paginationContainer.parentElement.style.display = 'none';
		} else {
			this.paginationContainer.style.display = 'none';
		}
	}

	handlePaginationClick(e) {
		const _ = this;

		// Trigger user interacted with carousel event
		_.carousel.emit('userInteracted');

		const target = e.target.closest('[data-action="tarot-go-to-page"]');

		// Exit if not one of the buttons
		if (!target) return;

		// Calculate which slide to jump to
		const pageIndex = parseInt(target.getAttribute('data-page-index'), 10);

		_.carousel.goToPage(pageIndex);
	}

	handleKeyDown(e) {
		const _ = this;
		let velocity = 0;
		const keys = ['ArrowLeft', 'ArrowRight', 'Home', 'End'];
		if (!keys.includes(e.key)) return;

		const tabs = Array.from(
			_.paginationContainer.querySelectorAll('.tarot-dots-button')
		);
		const currentIndex = tabs.findIndex(
			(tab) => tab.getAttribute('aria-selected') === 'true'
		);

		let newIndex;
		switch (e.key) {
			case 'ArrowRight':
				newIndex = currentIndex + 1;
				// if we aren't looping and we're out of bounds
				if (newIndex >= tabs.length && !_.carousel.options.loop) {
					// stay in bounds
					newIndex = currentIndex;
					// give it a bump
					velocity = -10;
				} else if (newIndex >= tabs.length && _.carousel.options.loop) {
					// loop back to front
					newIndex = 0;
				}
				break;

			case 'ArrowLeft':
				newIndex = currentIndex - 1;
				// if we aren't looping and we're out of bounds
				if (newIndex < 0 && !_.carousel.options.loop) {
					// stay in bounds, reset to 0
					newIndex = currentIndex;
					// give it a bump
					velocity = 10;
				} else if (newIndex < 0 && _.carousel.options.loop) {
					// loop to end of carousel
					newIndex = tabs.length - 1;
				}
				break;

			case 'Home':
				newIndex = 0;
				break;
			case 'End':
				newIndex = tabs.length - 1;
				break;
			default:
				return;
		}

		e.preventDefault();
		tabs[newIndex].focus();
		_.carousel.goToPage(newIndex, velocity);
	}

	destroy() {
		const _ = this;
		// Remove event listeners
		_.paginationContainer.removeEventListener(
			'click',
			_.handlePaginationClick.bind(_),
			false
		);
		_.paginationContainer.removeEventListener(
			'keydown',
			_.handleKeyDown.bind(_),
			false
		);
		_.carousel.off('optionsChanged', _.render);
		_.carousel.off('slidesChanged', _.render);
		_.carousel.off('pageChanged', _.updateSelectedDot);

		// Remove pagination container from DOM if it was self-generated
		if (
			_.paginationContainer &&
			_.paginationContainer.parentNode === _.carouselElement
		) {
			_.carousel.removeChild(_.paginationContainer.parentNode); // Remove the <nav> element
		}

		_.paginationContainer = null;
	}
}
