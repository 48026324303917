import { debounce } from '../utilities/toolkit';

export default class Autoplay {
  constructor(carousel) {
    const _ = this;
    _.carousel = carousel;
    _.autoplayTimer = null;
    _.debouncedResume = debounce(this.start.bind(this), 10000);
    _.bindEvents();
    _.reInit();
  }

  reInit() {
    const _ = this;
    if (_.carousel.options.autoplay) {
      _.start();
    } else {
      _.stop();
    }
  }

  bindEvents() {
    const _ = this;

    _.carousel.on('userInteracted', () => {
      _.pause();
    });
  }

  start() {
    const _ = this;
    _.stop(); // Clear any existing timer

    let interval = _.carousel.options.autoplay;
    if (interval === 0 || interval === false) return;
    if (interval === true) interval = 4000;

    _.autoplayTimer = setInterval(() => {
      _.carousel.next();
    }, interval);
  }

  stop() {
    clearInterval(this.autoplayTimer);
  }

  pause() {
    const _ = this;
    // stops the current timer
    _.stop();
    // restarts in 10 seconds if no interaction
    if (_.carousel.options.autoplay !== false) {
      _.debouncedResume();
    }
  }
}
