// import TarotPlugin from './tarot-plugin'
import TrackAnimator from './utilities/track-animator.js';
import { convertValueToNumber } from '../utilities/toolkit';
import LoopManager from './utilities/loop-manager.js';
import RippleWindow from './utilities/ripple-window.js';
import RippleTransforms from './utilities/ripple-transforms.js';

export default class RipplelMode {
	static name = 'ripple';

	constructor(carousel) {
		// super();
		const _ = this;
		_.carousel = carousel;
		_.track = carousel.track;
		_.viewport = carousel.viewport;

		_.animator = new TrackAnimator(carousel);

		_.loopManager = new LoopManager(carousel, this);

		_.rippleWindow = new RippleWindow(carousel, this);
		_.rippleTransforms = new RippleTransforms(carousel, this);

		// widths
		_.trackWidth = 0;
		_.frameWidth = 0;
		_.slideWidth = 0;
		_.gapWidth = 0;
		_.paddingLeftWidth = 0;
		_.paddingRightWidth = 0;

		_.sortedSlides;

		_.init();
	}

	init() {
		const _ = this;

		// set mode
		_.carousel.setAttribute('data-mode', _.constructor.name);

		// calculate widths with new options
		_.calculateWidths();

		// _.calculatePageCount();

		_.calculateSlideRenderIndex();

		_.bindUI();

		_.bindEvents();

		// this has to come after calculateWidths and bindEvents
		_.rippleWindow.init();
		_.rippleTransforms.init();

		// _.loopManager.updateLoop(0);
		_.positionSlides();

		// TODO: go to initial slide, which will trigger selectVisibleSlides
		_.jumpToPage(_.carousel.options.initialIndex);

		// #TODO set this up so it does this after the image has loaded
		setTimeout(() => {
			_.updateSmartButtons();
		}, 500);
	}

	reInit() {
		const _ = this;

		// stop animation in case it was happening
		_.animator.stop();

		// calculate widths with new options
		_.calculateWidths();

		// this has to come after calculateWidths and bindEvents
		_.rippleWindow.reInit();
		_.rippleTransforms.reInit();

		// _.calculatePageCount();

		_.positionSlides();

		_.jumpToPage(_.carousel.page);
	}

	bindUI() {
		const _ = this;

		// bind to loading of first image
		if (_.carousel.slides.length > 0) {
			let firstImage = _.carousel.slides[0].querySelector('img') || false;
			if (firstImage && firstImage.complete) {
				_.updateSmartButtons(firstImage);
			} else if (firstImage) {
				// update buttons after image loads
				firstImage.onload = function () {
					_.updateSmartButtons(firstImage);
				};
			}
		}
	}

	bindEvents() {
		const _ = this;

		_.carousel.on('windowResize', () => {
			_.reInit();
		});

		// callback for when animation is finished
		_.animator.on('animationFinished', (trackPos) => {
			// tell ripple window to snap to end value
			_.rippleWindow.snapToValue();

			// position slides with
			_.positionSlides();

			// transition is finished
			_.carousel.emit('carouselDidTransition');
		});

		_.animator.on('positionChanged', (trackPos, delta, progress, type) => {
			// console.log('animator = positionChanged', delta, progress, type);

			// tell loop manager about updated position
			_.loopManager.updateLoop(trackPos);

			// we need to get the updated track pos in case it looped
			trackPos = _.animator.getCurrentPos();

			// add amount to sliding window
			if (type !== 'jump' && progress <= 1) {
				_.rippleWindow.addAmount(delta, trackPos);
			}

			// position slides with
			_.positionSlides();
		});

		_.carousel.on('pageChanged', (data) => {
			const { newPage, velocity } = data[0];
			_.goToPage(newPage, velocity);
		});

		_.carousel.on('optionsChanged', () => {
			_.reInit();
		});

		_.loopManager.on('shiftTrackForward', () => {
			_.animator.shiftTrackForward();
		});

		_.loopManager.on('shiftTrackBackwards', () => {
			_.animator.shiftTrackBackwards();
		});

		_.loopManager.on('slidePositionsChanged', () => {});
	}

	// Helper functions for managing width, position, and updating state

	calculateWidths() {
		const _ = this;
		const options = _.carousel.options;
		const viewportWidth = _.viewport.offsetWidth;

		// get and set gap width on DOM
		_.gapWidth = convertValueToNumber(options.gap, viewportWidth);

		// Edge Padding
		_.paddingLeftWidth = convertValueToNumber(
			options.paddingLeft,
			viewportWidth
		);
		_.paddingRightWidth = convertValueToNumber(
			options.paddingRight,
			viewportWidth
		);

		// Frame width
		let frameWidth = viewportWidth;
		// remove slide gaps from frame width
		frameWidth -= _.gapWidth * (options.slidesToShow - 1);
		frameWidth -= _.paddingLeftWidth + _.paddingRightWidth;
		_.frameWidth = frameWidth;

		// Slide width
		_.slideWidth = frameWidth / options.slidesToShow;
		// set width of slides on DOM
		_.carousel.style.setProperty('--tarot-slide-width', `${_.slideWidth}px`);

		// slide min width
		_.slideMinWidth = convertValueToNumber(options.slideMinWidth, _.slideWidth);
		// don't let it be less than 50 px wide
		if (_.slideMinWidth < 40) _.slideMinWidth = 40;
		// don't let it go over max size
		const maxSize = (_.slideWidth - _.gapWidth) / 2;
		if (_.slideMinWidth > maxSize) _.slideMinWidth = maxSize;

		// set ripple window max value
		_.rippleWindow.setMaxValue(_.slideWidth - _.slideMinWidth);

		// Track width
		_.trackWidth = _.carousel.slides.length * (_.slideWidth + _.gapWidth);
		_.track.style.width = `${_.trackWidth}px`;
	}

	// calculatePageCount() {
	// 	const _ = this;
	// 	const slideCount = _.carousel.slides.length;
	// 	const slidesToScroll = _.carousel.options.slidesToScroll;
	// 	const pageCount = Math.ceil(slideCount / slidesToScroll);
	// 	// _.carousel.pageCount = pageCount;

	// 	// if current page is now out of bounds
	// 	if (_.carousel.page >= pageCount) {
	// 		// set to last possible page
	// 		_.carousel.page = pageCount - 1;
	// 	}
	// }

	calculateSlideRenderIndex() {
		const _ = this;
		const slides = _.carousel.slides;

		for (let i = 0, n = slides.length; i < n; ++i) {
			slides[i].setRenderIndex(i);
		}
	}

	positionSlides() {
		const _ = this;
		const start = performance.now();
		const slides = Array.from(_.carousel.slides);

		//
		slides.sort((a, b) => {
			return a.getRenderIndex() - b.getRenderIndex();
		});

		const slideWidth = _.slideWidth;
		const slideAndGapWidth = slideWidth + _.gapWidth;
		let slide, slideIndex, slideXPos;

		const trackPos = _.animator.getCurrentPos();
		const transformPercent = _.rippleWindow.getPercent();
		let totalRemovedRight = 0;

		// position slides on track and calculate right shift
		for (let i = 0, n = slides.length; i < n; ++i) {
			slide = slides[i];
			// get render index
			// might be different from actual slide index
			slideIndex = slide.getRenderIndex();

			// save track position and slide width
			slide.trackXPos = slideAndGapWidth * slideIndex;
			slide.displayXPos = slide.trackXPos;
			slide.width = _.slideWidth;
			slide.displayWidthRight = 0;
			slide.displayWidthLeft = 0;

			// calculate center pos for transforms
			slide.centerPos = slide.trackXPos + _.slideWidth / 2;

			// apply transforms
			_.rippleTransforms.applyTransforms(slide, trackPos, transformPercent);

			// calculate display width
			slide.displayWidth =
				_.slideWidth + slide.displayWidthRight + slide.displayWidthLeft;

			// console.log('slide.displayWidthLeft ', slide.displayWidthLeft);

			// calculate display pos based on right shift
			slide.displayXPos = slide.trackXPos + totalRemovedRight;

			// set position and width on track
			slide.style.transition = 'none';
			slide.style.width = `${slide.displayWidth}px`;
			// slide.style.transform = `translateX(${slide.displayXPos}px)`;

			// add width adjustment to right shift
			totalRemovedRight += slide.displayWidthRight;

			const end = performance.now();
			setTimeout(function () {
				// console.log('TIME: ', end - start);
			}, 1);
		}

		// calculate left shift
		let totalRemovedLeft = 0;
		// position slides on track
		for (let k = slides.length - 1; k > -1; --k) {
			slide = slides[k];

			totalRemovedLeft += slide.displayWidthLeft;

			// calculate display pos based on left shift
			slide.displayXPos = slide.displayXPos - totalRemovedLeft;

			// position on track
			slide.style.transform = `translateX(${slide.displayXPos}px)`;
		}
	}

	selectVisibleSlides() {
		const _ = this;
		const carousel = this.carousel;

		// loop through slides and set values
	}

	// Drag handlers
	dragStart(event, drag) {
		this.animator.dragStart();
		// console.log('\ncarousel - dragstart', drag);
	}

	dragMove(event, drag) {
		this.animator.dragMove(event, drag);
		// console.log('carousel - dragMove', drag);
	}

	dragEnd(event, drag) {
		const _ = this;
		const delta = Math.abs(drag.delta);

		// did we drag enough to transition to different slide?
		if (delta < _.carousel.options.dragThreshold) {
			// return back to original index
			_.goToPage(_.carousel.page, 0);
			return;
		}

		// which direction are we going?
		if (drag.delta < 0) {
			_.carousel.next(drag.velocity);
			return;
		}

		_.carousel.prev(drag.velocity);
	}

	slideClicked(e) {
		const _ = this;

		// get closest slide
		const closestSlide = e.target.closest('tarot-slide');
		// exit if there isn't one
		if (!closestSlide) return;

		// TODO: set selected slide

		// TODO: goto slide on click?
	}

	goToPage(newPage, velocity) {
		const _ = this;
		const pageCount = _.carousel.pageCount;
		const trackWidth = _.trackWidth;

		// figure out what direction we're going in
		// get position of page
		const currentPos = _.animator.getCurrentPos();
		let newPos = _.calculatePagePos(newPage);
		if (_.carousel.options.loop) {
			const posDelta = Math.abs(currentPos - newPos);
			// if the amount to scroll is greater than half the width of the track
			// then we probably want to go the other direction
			if (posDelta > _.track.offsetWidth / 2) {
				// which direction are we going in?
				if (newPos > currentPos) {
					// offset scroll to position by track length
					newPos -= trackWidth;
				} else {
					// offset scroll to position by track length
					newPos += trackWidth;
				}
			}
		}

		if (velocity === undefined) {
			// give it a little boost
			if (newPos < currentPos) {
				velocity = -15;
			} else {
				velocity = 15;
			}
		} else {
			// boost what we already have
			velocity *= 1.2;

			if (Math.abs(velocity) < 15) {
				// add more
				velocity *= 1.3;
			}
		}

		// console.log('go to page with velocity', newPage, velocity);

		_.animator.animateToPosition(newPos, velocity);
	}

	jumpToPage(pageIndex) {
		const _ = this;
		_.animator.jumpToPosition(_.calculatePagePos(pageIndex));
	}

	// calculates the scroll position of the page on the track
	calculatePagePos(pageIndex) {
		const _ = this;
		let pos =
			(_.slideWidth + _.gapWidth) *
			_.carousel.options.slidesToScroll *
			pageIndex;

		// remember to add left padding to position
		pos -= _.paddingLeftWidth;

		const maxPosition =
			_.track.offsetWidth -
			_.viewport.offsetWidth -
			_.gapWidth +
			_.paddingLeftWidth -
			0.1;

		// if we aren't looping, don't go past max position
		if (!_.carousel.options.loop && pos > maxPosition) {
			pos = maxPosition;
		}

		// remember that transforms are negative
		if (pos !== 0) pos *= -1;

		return pos;
	}

	goToSlide(index) {
		const _ = this;
		const slidesToScroll = _.carousel.options.slidesToScroll;
		// animate to page
		_.goToPage(Math.floor(index / slidesToScroll));
	}

	jumpToSlide(index) {
		const _ = this;
		const slidesToScroll = _.carousel.options.slidesToScroll;
		// jump to page
		_.jumpToPage(Math.floor(index / slidesToScroll));
	}

	// smart buttons
	updateSmartButtons(image) {
		const _ = this;
		const carousel = _.carousel;
		const smartButtons = carousel.options.smartButtons || false;
		const prevButton = carousel.prevButton;
		const nextButton = carousel.nextButton;
		const minDistance = 40;

		const viewportWidth = _.viewport.offsetWidth;

		// exit if we don't have any slides
		if (carousel.slides.length == 0) return;

		// if option is set to false
		if (!smartButtons) {
			// clear classes and styles
			if (prevButton) {
				prevButton.classList.remove('tarot-smart-position');
				// set top and left and right to none
			}
			if (nextButton) {
				nextButton.classList.remove('tarot-smart-position');
			}
			return;
		}

		// use track height as default
		let buttonTopPos = carousel.track.offsetHeight / 2;
		let slides = carousel.slides;
		// get first image
		let firstImage = slides[0].querySelector('img') || false;
		if (firstImage) {
			window.firstImage = firstImage;
			buttonTopPos = firstImage.height / 2;
		}

		// set prev button position
		if (prevButton) {
			prevButton.classList.add('tarot-smart-position');
			prevButton.style.top = `${buttonTopPos}px`;

			// start with 0 px
			let leftPos = 0;
			// only use gap width if left padding is there
			if (_.paddingLeftWidth) {
				if (_.gapWidth >= _.paddingLeftWidth) {
					leftPos = _.paddingLeftWidth;
				} else {
					leftPos = _.paddingLeftWidth - _.gapWidth / 2;
				}
			}

			// prevent button from going out of view
			// checks to see if carousel width goes to each edge of the browser
			if (window.innerWidth == _.viewportWidth && leftPos < minDistance) {
				leftPos = minDistance;
			}

			// set the button left position
			prevButton.style.left = `${leftPos}px`;
		}

		// set next button position
		if (nextButton) {
			nextButton.classList.add('tarot-smart-position');
			nextButton.style.top = `${buttonTopPos}px`;

			// calculate right position
			let rightPos = 0;
			// only use gap width if left padding is there
			if (_.paddingRightWidth) {
				if (_.gapWidth >= _.paddingRightWidth) {
					rightPos = _.paddingRightWidth;
				} else {
					rightPos = _.paddingRightWidth - _.gapWidth / 2;
				}
			}

			// prevent button from going out of view
			if (
				_.viewportWidth > window.innerWidth - minDistance * 2 &&
				rightPos < minDistance
			) {
				rightPos = minDistance;
			}

			nextButton.style.right = `${rightPos}px`;
		}
	}

	destroy() {
		const _ = this;
		delete _.animator;

		// clear track attributes
		_.track.removeAttribute('style');
		// clear smart button styles
		if (_.carousel.buttons.prev) {
			_.carousel.buttons.prev.removeAttribute('style');
			_.carousel.buttons.prev.classList.remove('tarot-smart-position');
		}
		if (_.carousel.buttons.next) {
			_.carousel.buttons.next.removeAttribute('style');
			_.carousel.buttons.next.classList.remove('tarot-smart-position');
		}

		// _.carousel.buttons.next.removeAttribute('style')
		// clear data from slides
		const slides = _.carousel.slides;
		for (let i = 0, n = slides.length; i < n; ++i) {
			slides[i].classList.remove('is-visible');
			slides[i].classList.remove('is-active');
			slides[i].removeAttribute('aria-hidden');
		}

		// clear slide widths
		_.carousel.style.removeProperty('--tarot-slide-width');
	}
}
