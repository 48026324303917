export default class Buttons {
  constructor(carousel) {
    const _ = this;
    _.carousel = carousel;
    _.prev = null;
    _.next = null;
    _.boundHandlePrevClick = _.handlePrevClick.bind(_);
    _.boundHandleNextClick = _.handleNextClick.bind(_);
    _.boundHandleButtonFocus = _.handleButtonFocus.bind(_);
    _.boundHandleButtonBlur = _.handleButtonBlur.bind(_);

    _.init();
  }

  init() {
    const _ = this;
    // pull buttons from DOM
    _.queryButtons();
    // build them if they don't exist
    // must be in this order for in the DOM
    if (!_.next) _.buildNextButton();
    if (!_.prev) _.buildPrevButton();

    // bind to UI
    _.checkButtonOptions();
    _.bindUI();
    _.bindEvents();
    _.checkDisabledState();
  }

  reInit() {
    const _ = this;
    _.checkButtonOptions();
    _.checkDisabledState();
  }

  checkButtonOptions() {
    const _ = this;
    _.carousel.options.showButtons ? _.showButtons() : _.hideButtons();
  }

  queryButtons() {
    const _ = this;
    _.prev =
      _.carousel.querySelector(':scope > [data-action="tarot-prev"]') || null;
    _.next =
      _.carousel.querySelector(':scope > [data-action="tarot-next"]') || null;
  }

  buildPrevButton() {
    const _ = this;
    const prevButtonHTML = `
      <button class="tarot-button" data-action="tarot-prev" aria-label="previous slide">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <title>angle left</title>
          <path d="m21 7-9 9 9 9" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </button>
    `;
    // add button to DOM
    _.carousel.insertAdjacentHTML('afterbegin', prevButtonHTML);
    // query DOM for button
    _.prev = _.carousel.querySelector('[data-action="tarot-prev"]');
    _.carousel.prevButton = _.prev;
  }

  buildNextButton() {
    const _ = this;
    const nextButtonHTML = `
      <button class="tarot-button" data-action="tarot-next" aria-label="next slide">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <title>angle right</title>
          <path d="m11 25 9-9-9-9" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </button>
    `;
    _.carousel.insertAdjacentHTML('afterbegin', nextButtonHTML);
    _.next = _.carousel.querySelector('[data-action="tarot-next"]');
    _.carousel.nextButton = _.next;
  }

  showButtons() {
    const _ = this;
    const prevButton = _.prev;
    const nextButton = _.next;

    if (prevButton) {
      prevButton.style.display = '';
      prevButton.setAttribute('aria-label', 'previous');
      prevButton.classList.add('tarot-button', 'tarot-prev');
    }
    if (nextButton) {
      nextButton.style.display = '';
      nextButton.setAttribute('aria-label', 'next');
      nextButton.classList.add('tarot-button', 'tarot-next');
    }
  }

  // gone but not forgotten
  hideButtons() {
    const _ = this;
    if (_.prev) _.prev.style.display = 'none';
    if (_.next) _.next.style.display = 'none';
  }

  bindUI() {
    const _ = this;
    // make sure we don't double bind
    _.unbindUI();
    if (_.prev) {
      _.prev.addEventListener('click', _.boundHandlePrevClick, true);
      _.prev.addEventListener('focus', _.boundHandleButtonFocus);
      _.prev.addEventListener('blur', _.boundHandleButtonBlur);
    }
    if (_.next) {
      _.next.addEventListener('click', _.boundHandleNextClick, true);
      _.next.addEventListener('focus', _.boundHandleButtonFocus);
      _.next.addEventListener('blur', _.boundHandleButtonBlur);
    }
  }

  unbindUI() {
    const _ = this;
    if (_.prev) {
      _.prev.removeEventListener('click', _.boundHandlePrevClick, true);
      _.prev.removeEventListener('focus', _.boundHandleButtonFocus);
      _.prev.removeEventListener('blur', _.boundHandleButtonBlur);
    }
    if (_.next) {
      _.next.removeEventListener('click', _.boundHandleNextClick, true);
      _.next.removeEventListener('focus', _.boundHandleButtonFocus);
      _.next.removeEventListener('blur', _.boundHandleButtonBlur);
    }
  }

  bindEvents() {
    const _ = this;
    _.carousel.on('optionsChanged', () => {
      _.reInit();
    });

    _.carousel.on('pageChanged', () => {
      _.checkDisabledState();
    });
  }

  checkDisabledState() {
    const _ = this;
    const page = _.carousel.page;
    // if we aren't looping
    if (!_.carousel.options.loop) {
      // check prev button
      if (page == 0) {
        _.prev.disabled = true;
      } else {
        _.prev.disabled = false;
      }

      // check next button
      if (page == _.carousel.pageCount - 1) {
        _.next.disabled = true;
      } else {
        _.next.disabled = false;
      }
    }
  }

  handlePrevClick() {
    const _ = this;
    _.carousel.emit('userInteracted');
    _.carousel.prev();
  }

  handleNextClick() {
    const _ = this;
    _.carousel.emit('userInteracted');
    _.carousel.next();
  }

  handleButtonFocus() {
    const _ = this;
    if (_.carousel.autoplay && _.carousel.plugin.pauseAutoPlay) {
      _.carousel.plugin.pauseAutoPlay();
    }
  }

  handleButtonBlur() {
    const _ = this;
    if (_.carousel.plugin && _.carousel.plugin.resumeAutoPlay) {
      _.carousel.startAutoplay();
      _.carousel.plugin.resumeAutoPlay();
    }
  }

  destroy() {
    const _ = this;
    _.unbindUI();
    _.prev = null;
    _.next = null;
  }
}
