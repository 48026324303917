import CarouselMode from '../modes/carousel.js';
// import CarouselCenterMode from '../modes/carousel-center.js';
import FadeMode from '../modes/fade.js';
import Ripple from '../modes/ripple.js';

export default class ModeManager {
  static modes = {
    carousel: CarouselMode,

    fade: FadeMode,
    ripple: Ripple,
  };

  static addMode(name, modeClass) {
    const _ = ModeManager;
    if (_.modes[name]) {
      console.warn(
        `Mode '${name}' already exists. It will be overwritten.`
      );
    }
    _.modes[name] = modeClass;
  }

  constructor(carousel) {
    const _ = this;
    _.carousel = carousel;
    _.init();
  }

  init() {
    const _ = this;
    const modeName = _.carousel.options.mode;
    _.loadMode(modeName);
    _.bindEvents();
  }

  bindEvents() {
    const _ = this;
    _.carousel.on('optionsChanged', () => {
      // only load new module if the name has changed
      if (
        _.carousel.options.mode !== _.carousel.mode.constructor.name
      ) {
        _.loadMode(_.carousel.options.mode);
      }
    });
  }

  loadMode(modeName) {
    const _ = this;
    if (!modeName) {
      console.error(
        'Error: no mode detected or could not load mode ',
        modeName
      );
      return;
    }
    const NewModeClass = ModeManager.modes[modeName];
    if (!NewModeClass) {
      console.error(
        `Error: could not find mode with name ${modeName}`
      );
      return;
    }
    // TODO: Destory mode
    // clear out old mode
    if (_.carousel.mode) {
      _.carousel.mode.destroy();
      _.carousel.mode = null;
    }
    // create new mode
    _.carousel.mode = new NewModeClass(_.carousel);
  }
}
