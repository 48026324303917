// styles
import '../css/tarot-carousel.scss';

// main Tarot Carousel calss
import TarotCarousel from './tarot-carousel.js';

// include custom HTML elements
import './core/custom-elements.js';

// Import Functions
import coreMethods from './core/methods.js';

// Plugins
import AsNavFor from './plugins/as-nav-for.js';
import Autoplay from './plugins/autoplay.js';
import Buttons from './plugins/buttons.js';
import LazyLoad from './plugins/lazy-load.js';
import PageDots from './plugins/page-dots.js';
import SyncWith from './plugins/sync-with.js';
import WindowEvents from './plugins/window-events.js';

// copy over functions to main class
Object.assign(TarotCarousel.prototype, coreMethods);

// add plugins
TarotCarousel.use(AsNavFor);
TarotCarousel.use(Autoplay);
TarotCarousel.use(Buttons);
TarotCarousel.use(LazyLoad);
TarotCarousel.use(PageDots);
TarotCarousel.use(SyncWith);
TarotCarousel.use(WindowEvents);

// register as custom element
customElements.define('tarot-carousel', TarotCarousel);

export default TarotCarousel;
