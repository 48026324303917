/**
 * @class SlideManager
 * A class to manage slides in a carousel-like structure.
 */
export default class SlideManager {
	/**
	 * @constructor
	 * @param {HTMLElement} track - The element that contains the slides.
	 * @param {Object} userOptions - Configuration options for the SlideManager.
	 */
	constructor(carousel) {
		const _ = this;
		_.carousel = carousel;

		_.coreSlides = null;

		this.events = {
			slidesChanged: () => {},
			selectedSlideChanged: (oldIndex, newIndex) => {},
		};

		_.init();
		_.observeDOMChanges();
	}

	/**
	 * Initialize slides by loading them from the DOM.
	 */
	init() {
		const _ = this;
		const track = _.carousel.track;

		// make sure slides are wrapped in <tarot-slide>
		_.wrapSlides();

		// pull the core slides from the DOM on initial load
		_.coreSlides = track.querySelectorAll(':scope > tarot-slide');

		// apply filter to slides, this is an initial load
		_.loadFilteredSlides(true);

		// always update slide indexes
		_.updateSlideIndexes();

		// set selected slide after they're loaded
		_.setSelectedSlide(_.selectedIndex);
	}

	reInit() {}

	wrapSlides() {
		const track = this.carousel.track;
		const childrenArray = Array.from(track.children);

		// Ensure all slides are wrapped in <tarot-slide> elements
		childrenArray.forEach((child) => {
			if (child.tagName.toLowerCase() !== 'tarot-slide') {
				// wrap in tarot-slide
				const wrapper = document.createElement('tarot-slide');
				track.replaceChild(wrapper, child);
				// place content in the slide
				wrapper.appendChild(child);
			}
		});
	}

	// will observe the DOM and watch to see if any new slides are added
	observeDOMChanges() {
		// create a new MutationObserver instance and pass a callback function
		const observer = new MutationObserver((mutationsList) => {
			for (const mutation of mutationsList) {
				if (mutation.type === 'childList') {
					// when a child is added or removed, reload the slides
					this.init();
				}
			}
		});

		// observe the target element (track) for changes to its children
		observer.observe(this.carousel.track, {
			childList: true, // listen for addition or removal of child elements
			subtree: false, // observe only the direct children of the track element
		});
	}

	getSlides() {
		return this.slides;
	}

	/**
	 * Load slides based on a filter class.
	 * @param {boolean} isInitialLoad - Whether this is the initial load.
	 */
	loadFilteredSlides(isInitialLoad = false) {
		const _ = this;
		const filterClass = _.carousel.options.filterClass;
		const coreSlides = _.coreSlides;
		const track = _.track;

		if (!filterClass) {
			// If filterClass is empty, use the original slides
			_.carousel.slides = coreSlides;
		} else {
			// Create a document fragment to hold the filtered slides
			const fragment = document.createDocumentFragment();

			// Filter slides based on the filterClass and add to the fragment
			coreSlides.forEach((slide) => {
				if (slide.classList.contains(filterClass)) {
					fragment.appendChild(slide.cloneNode(true));
				}
			});

			// Update slides to be a NodeList from the fragment
			_.carousel.slides = fragment.querySelectorAll('tarot-slide');
			console.log('slides are', _.carousel.slides);

			// Clear the existing children of track and append the new slides
			track.innerHTML = '';
			track.appendChild(fragment);
		}

		if (!isInitialLoad) {
			// Take appropriate action if slides have changed
			_.plugin.reinitPlugin();
			_.events.slidesChanged();
		}
	}

	/**
	 * Add a slide to the slides NodeList.
	 * @param {HTMLElement|string} element - The slide element or HTML string to add.
	 * @param {number} [index] - The index at which to add the slide. If not provided, the slide is added at the end.
	 */
	addSlide(element, index) {
		const _ = this;
		const track = _.track;

		// Check if element is an HTML string and convert to HTMLElement
		let newSlide;
		if (typeof element === 'string') {
			const tempDiv = document.createElement('div');
			tempDiv.innerHTML = `<tarot-slide>${element.trim()}</tarot-slide>`;
			newSlide = tempDiv.firstChild;
		} else {
			// Ensure the element is wrapped in a <tarot-slide> element
			const wrapper = document.createElement('tarot-slide');
			wrapper.appendChild(element);
			newSlide = wrapper;
		}

		// Add the slide at the specified index or at the end
		const slides = Array.from(_.slides);
		if (
			typeof index === 'number' &&
			index >= 0 &&
			index < slides.length
		) {
			track.insertBefore(newSlide, slides[index]);
		} else {
			track.appendChild(newSlide);
		}

		// Reload slides from DOM
		_.loadFilteredSlides();

		// Reinitialize plugin with updated slides
		// _.plugin.reinitPlugin();
		// _.events.slidesChanged();
	}

	/**
	 * Remove a slide from the slides NodeList at the specified index.
	 * @param {number} index - The index of the slide to remove.
	 */
	removeSlide(index) {
		const _ = this;
		const track = _.track;
		const slides = Array.from(_.coreSlides);

		// Check if index is valid
		if (index < 0 || index >= slides.length) {
			console.warn(`Index ${index} is out of bounds.`);
			return;
		}

		// TODO: remove from core slides
		// then reload flitered slides

		// remove child
		track.removeChild(slides[index]);

		// Reload slides
		_.loadFilteredSlides();
	}

	/**
	 * Update the data-index attribute for each slide.
	 */
	updateSlideIndexes() {
		const slides = this.carousel.slides;
		// Set the index for each slide
		for (let i = 0, n = slides.length; i < n; ++i) {
			slides[i].setAttribute('data-index', i);
		}
	}

	/**
	 * Set the selected slide and trigger necessary events.
	 * @param {number} newIndex - The new index of the selected slide.
	 */
	setSelectedSlide(newIndex) {
		const _ = this;
		// get all slides including clones
		const slides = _.carousel.slides;
		const oldIndex = _.selectedIndex;

		// if the new slide has a different index
		if (oldIndex !== newIndex) {
			_.selectedIndex = newIndex;
			// trigger event with old and new index
			_.carousel.events.selectedSlideChanged(oldIndex, newIndex);
		}

		// remove 'tarot-selected' from all other slides
		let slide;
		for (let i = 0, n = slides.length; i < n; ++i) {
			slide = slides[i];
			if (slide.getAttribute('data-index') == newIndex) {
				slide.classList.add('tarot-selected');
			} else {
				slide.classList.remove('tarot-selected');
			}
		}
	}
}
