const DIRECTION = {
  UNKNOWN: 0,
  VERTICAL: -1,
  HORIZONTAL: 1,
};

export default class DragHandler {
  #test;

  // constructor to initialize the class
  constructor(carousel) {
    const _ = this;
    _.carousel = carousel;
    // this is the element we will bind to
    _.track = carousel.track;

    // doesn't trigger a drag move on the mode plugin
    // until after the threshold has been met
    _.dragThreshold = 4;

    // initialize drag data
    _.drag = {
      isDragging: false,
      start: 0,
      current: 0,
      prev: 0,
      delta: 0,
      velocity: 0,
      dragThresholdMet: false,
    };

    _.bindUI();
  }

  /**
   * Initialize drag events on the carousel track element.
   */
  bindUI() {
    const _ = this;
    const track = _.track;

    track.addEventListener('click', (e) => _.handleClick(e));
    track.addEventListener('pointerdown', (e) => _.handleDragStart(e), { passive: false });
    track.addEventListener('pointermove', (e) => _.handleDragMove(e), { passive: false });
    track.addEventListener('pointerup', (e) => _.handleDragEnd(e), { passive: false });
    track.addEventListener('pointerleave', (e) => _.handleDragEnd(e), { passive: false });
    track.addEventListener('pointercancel', (e) => _.handleDragEnd(e), { passive: false });
    track.addEventListener('touchstart', (e) => _.handleTouchStart(e), { passive: true });
    track.addEventListener('touchmove', (e) => _.handleTouchMove(e), { passive: false });

    // Prevent double taps
    track.addEventListener('dblclick', (e) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    });
  }

  /**
   * Handle click events on the track.
   * Prevents click if drag threshold was met.
   * @param {Event} e - The click event.
   */
  handleClick(e) {
    const _ = this;

    _.carousel.emit('userInteracted');

    // user was dragging - not a click
    if (_.drag.dragThresholdMet) {
      e.preventDefault();
      return;
    }

    // trigger click event
    _.carousel.mode.slideClicked(e);
    // _.carousel.emit('slideClicked', e)
  }

  /**
   * Handle touch start events on the track.
   * Disables dragging if more than one touch is detected.
   * @param {TouchEvent} e - The touch start event.
   */
  handleTouchStart(e) {
    const _ = this;
    _.carousel.emit('userInteracted');

    // tell plugin user clicked on slide
    if (e.touches.length > 1) {
      _.drag.isDragging = false;
    }
  }

  /**
   * Handle touch move events on the track.
   * Determines the direction of touch movement and
   * prevents vertical scrolling if horizontal movement is detected.
   * @param {TouchEvent} e - The touch move event.
   */
  handleTouchMove(e) {
    const _ = this;
    const drag = _.drag;

    _.carousel.emit('userInteracted');

    // exit if a touchstart drag wasn't initiated
    if (!drag.isDragging) return;

    // exit if we have already determined we are
    // scrolling horizontally and not vertically
    if (drag.touchDirection === DIRECTION.HORIZONTAL) {
      e.preventDefault();
      return;
    }

    // calculate delta X and Y values from touchmove events
    const deltaX = Math.abs(drag.startX - e.touches[0].screenX);
    const deltaY = Math.abs(drag.startY - e.touches[0].screenY);

    // confirm we are scrolling horizontially
    if (deltaX * 1.15 > deltaY) {
      // prevent scrolling on page
      e.preventDefault();
      // save touch direction as horizontal
      drag.touchDirection = DIRECTION.HORIZONTAL;
      return;
    }

    // cancel drag because drag direction is vertical
    drag.isDragging = false;
    drag.touchDirection = DIRECTION.VERTICAL;
    drag.delta = 0;

    // tell plugin we are ending drag because direction is vertical
    _.carousel.mode.dragEnd(e, drag);
    // _.carousel.emit('dragEnd', e, drag)
  }

  /**
   * Handle drag start events on the track.
   * Initializes drag data and notifies the plugin.
   * @param {PointerEvent} e - The pointer down event.
   */
  handleDragStart(e) {
    // we need this to properly capture drag events
    e.preventDefault();

    const _ = this;
    const drag = _.drag;

    drag.hasTouch = e.pointerType === 'touch';
    drag.touchDirection = DIRECTION.UNKNOWN;

    // save initial drag start values
    drag.isDragging = true;
    drag.dragThresholdMet = false;
    drag.startX = e.screenX;
    drag.startY = e.screenY;
    drag.velocity = 0;
    drag.delta = 0;

    // let plugin know drag start has begin
    _.carousel.mode.dragStart(e, drag);
    _.carousel.emit('userInteracted');
  }

  /**
   * Handle drag move events on the track.
   * Updates drag data and notifies the plugin if the drag threshold is met.
   * @param {PointerEvent} e - The pointer move event.
   */
  handleDragMove(e) {
    const _ = this;
    const drag = _.drag;

    _.carousel.emit('userInteracted');

    // exit if we are not actively dragging
    if (!drag.isDragging) return;

    // exit if has touch and direction is vertical or undefined
    if (drag.hasTouch && drag.touchDirection != DIRECTION.HORIZONTAL) {
      return;
    }

    // we are dragging horizontally so we must prevent scrolling on page
    e.preventDefault();

    // update drag event values with current event data
    drag.prev = drag.current;
    drag.current = e.screenX;
    drag.delta = drag.current - drag.startX;
    drag.velocity = drag.current - drag.prev;

    // check to see if drag threshold has been met
    if (!drag.dragThresholdMet && Math.abs(drag.delta) > _.dragThreshold) {
      drag.dragThresholdMet = true;
    }

    // exit and don't move if threshold hasn't been met yet
    if (!drag.dragThresholdMet) return;

    // tell plugin a drag move has occured
    _.carousel.mode.dragMove(e, drag);
    return false;
  }

  /**
   * Handle drag end events on the track.
   * Finalizes drag data and notifies the plugin.
   * @param {PointerEvent} e - The pointer up or pointer leave event.
   */
  handleDragEnd(e) {
    const _ = this;
    const drag = _.drag;

    _.carousel.emit('userInteracted');

    // console.log("Drag-Events: drag end - is dragging: ", drag.isDragging )

    // exit if we are not actively dragging
    if (!drag.isDragging) return;

    // prevent scrolling on page
    e.preventDefault();

    // we are now done dragging
    drag.isDragging = false;

    // tell plugin the drag has ended
    _.carousel.mode.dragEnd(e, drag);
  }

  destroy() {}
}
