// import TweenCalculator from '../library/tween-calculator.js';
// import PhysicsEngine from '../library/physics-engine.js';

class TarotViewport extends HTMLElement {
  constructor() {
    super();
  }
}

class TarotSlides extends HTMLElement {
  constructor() {
    super();
  }
}

class TarotSlide extends HTMLElement {
  // This is the index the slide will render at
  // The LoopManager uses this to loop slides on the track
  // The renderIndex might be different from the slide index
  #renderIndex;

  constructor() {
    super();
    //const _ = this;

    // _.engine = new PhysicsEngine();
    // _.tween = new TweenCalculator();
    // _.trackPos = 0;
  }

  setRenderIndex(renderIndex) {
    this.#renderIndex = renderIndex;
  }

  getRenderIndex() {
    return this.#renderIndex;
  }

  // animateToStyle(styles) {
  //   const _ = this;
  //   // stop animation
  //   _.engine.stop();
  //   // start physics engine
  //   // load tween
  // }

  // jumpToStyle(styles) {
  //   const _ = this;
  //   // stop animation
  //   _.engine.stop();
  //   // set styles
  // }

  // stopAnimation() {
  //   const _ = this;
  //   _.engine.stop();
  //   // clear styles
  // }

  // setTrackPos(pos) {
  //   console.log('slide setTrackPos');
  //   this.trackPos = pos;
  //   return this;
  // }

  // jumpToPos(pos) {
  //   const _ = this;
  //   _.style.transition = 'none';
  //   _.style.transform = `translateX(${_.trackPos}px)`;
  //   return _;
  // }

  // jumpToTrackPos(pos) {
  //   console.log('slide jumpToTrackPos');
  //   const _ = this;
  //   if (pos) {
  //     _.trackPos = pos;
  //   }
  //   _.style.transition = 'none';
  //   _.style.transform = `translateX(${_.trackPos}px)`;
  //   return _;
  // }
}

class TarotSlideContent extends HTMLElement {
  constructor() {
    super();
  }
}

class TarotSlideIcon extends HTMLElement {
  constructor() {
    super();
  }
}

customElements.define('tarot-viewport', TarotViewport);
customElements.define('tarot-slides', TarotSlides);
customElements.define('tarot-slide', TarotSlide);
customElements.define('tarot-slide-content', TarotSlideContent);
customElements.define('tarot-slide-icon', TarotSlideIcon);
