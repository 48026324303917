

export default class SyncWith {

	constructor(carousel){
		const _ = this;
		_.carousel = carousel;
		_.otherCarousel = null;
		_.init()
	}

init () {
	const _ = this
	const otherCarouselId = _.carousel.options.syncWith;

	// exit if no value
	if (!otherCarouselId) return;

	// get syncWith value from options
	_.otherCarousel = document.querySelector(_.options.syncWith)

	// can't find it
	if (_.syncWithCarousel == undefined) return;

	// give DOM a few ms to load the custom elements 
	setTimeout(() => {
		_.syncWithCarousel.on('carouselWillTransition', function(prevIndex, nextIndex) {
			// return if we are already going to that index or already at it 
			if ( _.displayIndex == nextIndex) return
			// go to same display index as other carousel
			_.goToSlide(nextIndex)
		})
	}, 100)
	
}

bindEvents () {
	const _ = this

	// bind to carouselWillTransition

	// bind to carouselWillTransition

}

updateSyncWithCarousel () {
	const _ = this

	// return if we don't have a carousel to update
	if (_.syncWithCarousel == undefined) return;

	// tell carousel to go to the same display index
	_.syncWithCarousel.goToSlide(_.displayIndex)		
}

destroy() {

}


}

