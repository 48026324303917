import { throttle, debounce } from '../utilities/toolkit.js';

export default class WindowEvents {
  constructor(carousel) {
    const _ = this;
    _.carousel = carousel;

    _.resizeHandlers = {
      throttled: throttle(_.handleResize.bind(_), 40),
      debounced: debounce(_.handleResize.bind(_), 100),
    };

    _.focusHandlers = {
      windowFocus: _.handleWindowFocus.bind(_),
      windowBlur: _.handleWindowBlur.bind(_),
      carouselFocus: _.handleCarouselFocus.bind(_),
      carouselBlur: _.handleCarouselBlur.bind(_),
    };

    _.init();
  }

  init() {
    this.bindResizeEvent();
    this.bindFocusEvents();
  }

  bindResizeEvent() {
    window.addEventListener('resize', (event) => {
      this.resizeHandlers.throttled(event);
      this.resizeHandlers.debounced(event);
    });
  }

  handleResize(event) {
    this.carousel.emit('windowResize');
  }

  bindFocusEvents() {
    const _ = this;
    window.addEventListener('focus', _.focusHandlers.windowFocus);
    window.addEventListener('blur', _.focusHandlers.windowBlur);
    _.carousel.addEventListener('focus', _.focusHandlers.carouselFocus, true);
    _.carousel.addEventListener('blur', _.focusHandlers.carouselBlur, true);
  }

  handleWindowFocus() {
    this.carousel.emit('carouselHasFocus');
  }

  handleWindowBlur() {
    this.carousel.emit('carouselLostFocus');
  }

  handleCarouselFocus() {
    this.carousel.emit('carouselHasFocus');
  }

  handleCarouselBlur() {
    this.carousel.emit('carouselLostFocus');
  }

  unbindFocusEvents() {
    const _ = this;
    window.removeEventListener('focus', _.focusHandlers.windowFocus);
    window.removeEventListener('blur', _.focusHandlers.windowBlur);
    _.carousel.removeEventListener('focus', _.focusHandlers.carouselFocus, true);
    _.carousel.removeEventListener('blur', _.focusHandlers.carouselBlur, true);
  }

  destroy() {
    const _ = this;
    window.removeEventListener('resize', _.resizeHandlers.throttled);
    window.removeEventListener('resize', _.resizeHandlers.debounced);
    _.unbindFocusEvents();
  }
}
