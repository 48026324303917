// Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)

function throttle(callback, limit) {
  let wait = false; // Initially, we're not waiting
  return function () {
    // We return a throttled function
    if (!wait) {
      // If we're not waiting
      callback.call(); // Execute users function
      wait = true; // Prevent future invocations
      setTimeout(function () {
        // After a period of time
        wait = false; // And allow future invocations
      }, limit);
    }
  };
}

function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    let context = this;
    let args = arguments;

    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

/**
 * Deep merge two objects.
 * @param {Object} target - The target object.
 * @param {Object} source - The source object.
 * @returns {Object} - The merged object.
 */
function deepMerge(target, source) {
  const isObject = (obj) => obj && typeof obj === 'object';

  return Object.keys(source).reduce(
    (acc, key) => {
      if (Array.isArray(acc[key]) && Array.isArray(source[key])) {
        acc[key] = acc[key].concat(source[key]);
      } else if (isObject(acc[key]) && isObject(source[key])) {
        acc[key] = deepMerge({ ...acc[key] }, source[key]);
      } else {
        acc[key] = source[key];
      }
      return acc;
    },
    { ...target }
  );
}

// accepts number (int or float), Pixel value "10px", or percent "10%"
// returns a numerical value
function convertValueToNumber(value, width) {
  if (typeof value == 'number') {
    // if value is a percent between 0 and 1
    if (value > 0 && value < 1) {
      return value * width;
    }
    // else it's just a number
    return value;
  }
  if (value.indexOf('px') > -1) {
    return parseFloat(value.replace('px', ''));
  } else if (value.indexOf('%') > -1) {
    return (parseFloat(value.replace('%', '')) / 100) * width;
  }
  return 0;
}

function areOptionsEqual(obj1, obj2) {
  // Check for reference equality
  if (obj1 === obj2) return true;

  // Check if both are objects
  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false;
  }

  // Handle arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;
    for (let i = 0; i < obj1.length; i++) {
      if (!areOptionsEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) return false;

  // Compare keys and their values
  for (const key of keys1) {
    if (!keys2.includes(key) || !areOptionsEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export { throttle, debounce, deepMerge, convertValueToNumber, areOptionsEqual };
