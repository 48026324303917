import EventEmitter from './event-emitter.js';

export default class PhysicsEngine {
  #attraction;
  #friction;
  #frictionFactor;
  #velocity;
  #currentValue;
  #targetValue;
  #isAnimating;
  #prevTime;
  #eventEmitter;
  #accumulatedTime;

  /**
   * Creates an instance of PhysicsEngine.
   * @param {number} [attraction=0.026] - The attraction value for physics-based animation (0 < attraction < 1).
   * @param {number} [friction=0.28] - The friction value for physics-based animation (0 < friction < 1).
   */
  constructor({ attraction = 0.026, friction = 0.28 } = {}) {
    const _ = this;
    _.#validateAttraction(attraction);
    _.#validateFriction(friction);

    _.#attraction = attraction;
    _.#friction = friction;
    _.#frictionFactor = 1 - friction;

    _.#velocity = 0;
    _.#currentValue = 0;
    _.#targetValue = 0;

    _.#isAnimating = false;
    _.#prevTime = null;

    _.#eventEmitter = new EventEmitter();
  }

  // new
  // animateToNEW(startValue, endValue, initialVelocity) {
  //   const _ = this;

  //   console.log('initialVelocity', initialVelocity);
  //   initialVelocity *= 10;

  //   _.#currentValue = startValue;
  //   _.#targetValue = endValue;
  //   _.#velocity = initialVelocity || 0;
  //   _.#isAnimating = true;
  //   _.#prevTime = null;

  //   const mass = 1; // Mass of the object
  //   const stiffness = 16; // Spring stiffness coefficient
  //   let damping = 3 * Math.sqrt(stiffness * mass); // Critical damping coefficient
  //   damping = 16;
  //   console.log('damping', damping);

  //   let totalDistance = endValue - startValue;
  //   // totalDistance = totalDistance / 3;
  //   // console.log('totalDistance', totalDistance);

  //   const animateStep = (timestamp) => {
  //     if (!_.#isAnimating) return;

  //     if (_.#prevTime === null) {
  //       _.#prevTime = timestamp;
  //     }

  //     const deltaTime = (timestamp - _.#prevTime) / 100; // Convert to seconds
  //     _.#prevTime = timestamp;

  //     // Spring physics calculations
  //     let displacement = _.#targetValue - _.#currentValue;
  //     // console.log('displacement', displacement);
  //     // if (displacement > 0 && displacement > 800) {
  //     //   displacement = 800;
  //     // } else if (displacement < 0 && displacement < -800) {
  //     //   displacement = -800;
  //     // }
  //     const springForce = stiffness * displacement;
  //     const dampingForce = damping * _.#velocity;
  //     const force = springForce - dampingForce;

  //     // Update velocity and position
  //     _.#velocity += (force / mass) * deltaTime;
  //     const posDelta = _.#velocity * deltaTime;
  //     _.#currentValue += posDelta;
  //     // console.log('\nposDelta', posDelta);

  //     // console.log('deltaTime', deltaTime);

  //     // console.log('velocity', _.#velocity);

  //     const distanceCovered = _.#currentValue - startValue;
  //     let progress =
  //       totalDistance !== 0 ? distanceCovered / totalDistance : 0;

  //     // Emit position changed event
  //     _.#eventEmitter.emit('positionChanged', {
  //       position: _.#currentValue,
  //       progress,
  //       posDelta,
  //     });

  //     // Check if animation should stop
  //     if (
  //       Math.abs(displacement) < 0.001 &&
  //       Math.abs(_.#velocity) < 0.001
  //     ) {
  //       _.#currentValue = _.#targetValue;
  //       _.#isAnimating = false;
  //       // Optionally emit a final event or callback here
  //     } else {
  //       requestAnimationFrame(animateStep);
  //     }
  //   };

  //   // Start the animation
  //   requestAnimationFrame(animateStep);
  // }

  /**
   * Animates from a start value to an end value.
   * @param {number} startValue - The starting value.
   * @param {number} endValue - The target value.
   * @param {number} velocity - The initial velocity.
   */
  animateTo(startValue, endValue, initialVelocity) {
    const _ = this;

    initialVelocity *= 1.8;

    _.#currentValue = startValue;
    _.#targetValue = endValue;
    _.#velocity = initialVelocity;
    _.#isAnimating = true;
    _.#prevTime = null;

    const totalDistance = _.#targetValue - startValue;

    const animate = (time) => {
      // stop animating
      if (!_.#isAnimating) return;

      // if we don't have a previous time
      if (_.#prevTime === null) {
        _.#prevTime = time;
        requestAnimationFrame(animate);
        return;
      }

      const timeDelta = time - _.#prevTime;
      _.#prevTime = time;
      const timeDeltaFactor = timeDelta / 10;

      // Calculate attraction force
      let displacement = _.#targetValue - _.#currentValue;
      const force = displacement * _.#attraction;

      // console.log('force', force);

      // Update velocity
      _.#velocity += force * timeDeltaFactor;
      // console.log('velocity 0 ', _.#velocity);

      // Apply friction
      _.#velocity *= Math.pow(_.#frictionFactor, timeDeltaFactor);

      // console.log('velocity 1', _.#velocity);

      // Update current value
      const posDelta = _.#velocity * timeDeltaFactor;
      _.#currentValue += posDelta;

      // console.log('posDelta', posDelta);

      // Calculate progress
      const distanceCovered = _.#currentValue - startValue;
      let progress = totalDistance !== 0 ? distanceCovered / totalDistance : 0;

      // Check if animation is complete
      if (
        Math.abs(posDelta) < 0.01 &&
        Math.abs(_.#currentValue - _.#targetValue) < 0.1
      ) {
        _.#isAnimating = false;
        _.#currentValue = _.#targetValue;

        // Emit final position
        _.#eventEmitter.emit('positionChanged', {
          position: _.#currentValue,
          progress: 1,
          posDelta: 0,
        });

        _.#eventEmitter.emit('animationFinished');
        // exit
        return;
      }

      // Emit position changed event
      _.#eventEmitter.emit('positionChanged', {
        position: _.#currentValue,
        progress,
        posDelta,
      });

      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);
  }

  /**
   * Stops the ongoing animation.
   */
  stop() {
    const _ = this;
    _.#isAnimating = false;
  }

  isAnimating() {
    return this.#isAnimating;
  }

  /**
   * Sets the attraction value
   * @param {number} attraction - The attraction value for physics-based animation (0 < attraction < 1).
   */
  setAttraction(attraction) {
    const _ = this;
    _.#validateAttraction(attraction);
    _.#attraction = attraction;
  }

  /**
   * Sets the friction value
   * @param {number} friction - The friction value for physics-based animation (0 < friction < 1).
   */
  setFriction(friction) {
    const _ = this;
    _.#validateFriction(friction);
    _.#friction = friction;
    _.#frictionFactor = 1 - friction;
  }

  /**
   * Adds an event listener for the specified event.
   * @param {string} eventName - The name of the event.
   * @param {function} eventFunction - The function to call when the event is triggered.
   */
  on(eventName, eventFunction) {
    this.#eventEmitter.on(eventName, eventFunction);
  }

  /**
   * Removes an event listener for the specified event.
   * @param {string} eventName - The name of the event.
   * @param {function} eventFunction - The function to remove.
   */
  // off(eventName, eventFunction) {
  //   const _ = this;
  //   _.#eventEmitter.off(eventName, eventFunction);
  // }

  #validateAttraction(attraction) {
    if (typeof attraction !== 'number' || attraction <= 0 || attraction >= 1) {
      throw new Error(
        'Attraction must be a number between 0 and 1 (exclusive).'
      );
    }
  }

  #validateFriction(friction) {
    if (typeof friction !== 'number' || friction <= 0 || friction >= 1) {
      throw new Error('Friction must be a number between 0 and 1 (exclusive).');
    }
  }
}
