import TransformRegion from './transform-region.js';

export default class RippleTransforms {
	constructor(carousel, mode) {
		const _ = this;
		_.carousel = carousel;
		_.mode = mode;
		_.track = carousel.track;
		_.slides = carousel.slides;
		_.viewport = carousel.viewport;
		_.paddingLeftWidth = 0;
		_.frameWidth = 0;

		// left ranges
		_.leftEndToMin = new TransformRegion({
			name: 'left end to min',
			property: 'displayWidthLeft',
		});

		_.leftMinToSquished = new TransformRegion({
			name: 'left min to squished',
			property: 'displayWidthLeft',
		});

		_.leftSquishedToFull = new TransformRegion({
			name: 'left squished to full',
			property: 'displayWidthLeft',
		});

		// Left Masked on exit
		_.leftFullToMin = new TransformRegion({
			name: 'left full to min',
			property: 'displayWidthLeft',
		});

		_.leftFullToMinEnd = new TransformRegion({
			name: 'left full to min end',
			property: 'displayWidthLeft',
		});

		// right transform ranges
		_.rightEndToMin = new TransformRegion({
			name: 'right width end',
			property: 'displayWidthRight',
		});

		_.rightMinToSquished = new TransformRegion({
			name: 'right min to squished',
			property: 'displayWidthRight',
		});

		_.rightSquishedToFull = new TransformRegion({
			name: 'right squished to full',
			property: 'displayWidthRight',
		});

		// Right Masked on exit
		_.rightFullToMin = new TransformRegion({
			name: 'right full to min',
			property: 'displayWidthRight',
		});

		_.rightFullToMinEnd = new TransformRegion({
			name: 'right full to min end',
			property: 'displayWidthRight',
		});
	}

	init() {
		const _ = this;
		_.bindEvents();
		_.reInit();
	}

	reInit() {
		const _ = this;
		_.viewportWidth = _.viewport.offsetWidth;
		_.paddingLeftWidth = _.mode.paddingLeftWidth;
		_.frameWidth = _.mode.frameWidth;
		_.slideWidth = _.mode.slideWidth;
		_.gapWidth = _.mode.gapWidth;
		_.shiftAmount = _.mode.slideMinWidth + _.gapWidth;

		_.calculateTransformPoints();
	}

	bindEvents() {
		const _ = this;

		_.carousel.on('windowResize', () => {
			_.reInit();
		});

		_.carousel.on('optionsChanged', () => {
			_.reInit();
		});
	}

	calculateTransformPoints() {
		const _ = this;

		const slidesToShow = _.carousel.options.slidesToShow;
		const halfSlideWidth = _.slideWidth / 2;
		const slideWidth = _.slideWidth;
		const slideAndGapWidth = _.slideWidth + _.gapWidth;
		const slideMinWidth = _.mode.slideMinWidth;
		const minSize = (slideWidth - slideMinWidth) * -1;
		const removedAmount = (_.gapWidth + slideMinWidth) * -1;

		// calculate points for left side
		const pointL2 = halfSlideWidth + _.mode.paddingLeftWidth;
		const pointL1 = pointL2 - slideAndGapWidth;
		const pointLMaskOut =
			pointL2 - slideAndGapWidth + slideMinWidth + _.mode.paddingLeftWidth;
		const pointL3 = pointL2 + slideAndGapWidth;

		// Left transition in
		_.leftEndToMin.updateOptions({
			startPos: pointL1 - _.track.offsetWidth,
			endPos: pointL1,
			startValue: minSize,
			endValue: minSize,
		});

		_.leftMinToSquished.updateOptions({
			startPos: pointL1,
			endPos: pointL2,
			startValue: minSize,
			endValue: removedAmount,
		});

		_.leftSquishedToFull.updateOptions({
			startPos: pointL2,
			endPos: pointL3,
			startValue: removedAmount,
			endValue: 0,
		});

		// Left masked
		_.leftFullToMin.updateOptions({
			startPos: pointLMaskOut,
			endPos: pointL2,
			startValue: minSize,
			endValue: 0,
		});

		_.leftFullToMinEnd.updateOptions({
			startPos: pointLMaskOut - _.track.offsetWidth,
			endPos: pointLMaskOut,
			startValue: minSize,
			endValue: minSize,
		});

		// calculate points for right side
		const pointR2 = _.viewportWidth - halfSlideWidth - _.mode.paddingRightWidth;
		const pointR1 = pointR2 - slideAndGapWidth;
		const pointR3 = pointR2 + slideAndGapWidth;
		const pointR4 = pointR3 + _.track.offsetWidth;

		const pointRMaskOut =
			pointR2 + slideAndGapWidth - slideMinWidth - _.mode.paddingRightWidth;

		const shiftNegative = _.shiftAmount * -1;

		_.rightSquishedToFull.updateOptions({
			startPos: pointR2,
			endPos: pointR1,
			startValue: removedAmount,
			endValue: 0,
		});

		_.rightMinToSquished.updateOptions({
			startPos: pointR3,
			endPos: pointR2,
			startValue: minSize,
			endValue: removedAmount,
		});

		_.rightEndToMin.updateOptions({
			startPos: pointR4,
			endPos: pointR3,
			startValue: minSize,
			endValue: minSize,
		});

		_.rightFullToMin.updateOptions({
			startPos: pointRMaskOut,
			endPos: pointR2,
			startValue: minSize,
			endValue: 0,
		});

		_.rightFullToMinEnd.updateOptions({
			startPos: pointRMaskOut + _.track.offsetWidth,
			endPos: pointRMaskOut,
			startValue: minSize,
			endValue: minSize,
		});
	}

	// make sure trackPos has paddingLeftWidth removed before calling
	applyTransforms(slide, trackPos, percent) {
		const _ = this;

		// apply left transforms
		_.leftEndToMin.applyTransform(slide, percent, trackPos);
		_.leftMinToSquished.applyTransform(slide, percent, trackPos);
		_.leftSquishedToFull.applyTransform(slide, percent, trackPos);

		_.leftFullToMin.applyTransform(slide, 1 - percent, trackPos);
		_.leftFullToMinEnd.applyTransform(slide, 1 - percent, trackPos);

		// apply right transforms
		_.rightEndToMin.applyTransform(slide, 1 - percent, trackPos);
		_.rightMinToSquished.applyTransform(slide, 1 - percent, trackPos);
		_.rightSquishedToFull.applyTransform(slide, 1 - percent, trackPos);

		_.rightFullToMin.applyTransform(slide, percent, trackPos);
		_.rightFullToMinEnd.applyTransform(slide, percent, trackPos);
	}

	destroy() {}
}
